
export const ISlot = (props) => {
	return (
		<div className="inventory__slot" onClick={props.toggle}>{props.children}</div>
		)
}

export const Inventory = (props) => {
	return (

	<div className="inventory">{props.children}</div>
	)
}
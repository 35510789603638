import { contractConfigs } from './contractConfigs.js'
import Web3 from 'web3'
import jemimg from './images/jem.png'

var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')

var mversetract = new web3.eth.Contract(contractConfigs["multiverse"]["abi"], contractConfigs["multiverse"]["address"])
var loretract = new web3.eth.Contract(contractConfigs["lore"]["abi"], contractConfigs["lore"]["address"])

var jemtract = new web3.eth.Contract(contractConfigs["jem"]["abi"], contractConfigs["jem"]["address"])
export const mintJem = (jid, cb = () => {}) => {

	mversetract.methods.mintJem(jid)
		.send({from: window.ethereum.selectedAddress})
		.then(cb)
}

export const mintLore = (lid, cb = () => {}) => {
	approveLoreSpendJem(
		() => {
			loretract.methods.mintLore(lid)
			.send({from: window.ethereum.selectedAddress})
			.then(cb)
		}
		)
}


export const getJemBalance = (cb = () => {}) => {
	jemtract.methods.balanceOf(window.ethereum.selectedAddress)
		.call({from: window.ethereum.selectedAddress})
		.then(cb)
}

export const getUserInfo = (cb = () => {}) => {
	mversetract.methods.userInfo(window.ethereum.selectedAddress)
		.call({from: window.ethereum.selectedAddress})
		.then(cb)
}

export const approveLoreSpendJem = (cb = () => {}) => {

	jemtract.methods.allowance(window.ethereum.selectedAddress, contractConfigs["lore"]["address"])
			.call({from: window.ethereum.selectedAddress})
			.then( 
				(allowance) => {
					if (allowance < 1) {
						jemtract.methods.approve(contractConfigs["lore"]["address"], "100000")
							.send({from: window.ethereum.selectedAddress})
							.then(cb)
						} else {
							cb()
						}
					
				
				}
				)
	

	
}

export const JemBalance = (props) => {
	return (
		<div className="dash dash--top-right jem-balance">{props.bal} <img src={jemimg} /></div>

		)
}

export const getLoreBalance = (lid, cb = () => {}) => {
	var x = loretract.methods.balanceOf(window.ethereum.selectedAddress, lid)
	console.log("getLoreBalance", x.call.request({from: window.ethereum.selectedAddress}, cb))
	return x.call.request({from: window.ethereum.selectedAddress}, cb)
		

}

export const LoreBalance = (props) => {
	return (
		<div className="dash dash--right lore-balance"><span>{props.bal} </span><img src={jemimg} /></div>

		)
}
import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';
import { CHEF, ADDY_REWARD, ADDY_STK } from './address.js'
import { BigNumber } from '@ethersproject/bignumber';


import logo from './images/eye.png';
import icon__MLNL from './images/MLNL.png';
import icon__MIM from './images/MIM.png';
import icon__FTM from './images/FTM.png';
import icon__stk from './images/mlnlMIMFTM.png';
import './App.scss';


import { Pool, PoolX } from './PoolElement.js';

import { chainMap } from './ChainTools.js';

import { poolConfigs } from './pubConfig.js';
import { Pu } from './PoolUtilities.js'
import { Secret } from './secret.js'

import truthmapmulti from './images/truthsector.png'

import { ekalaze } from './u2prelude00001.js'
import { RpgStyleConvo } from './rpgstuffimadefeelfreetostealretards.js'

import { IozeChar, IozeWord, IozeSelect, IozeSelectX, iozeAlphabet, getStatEffectFromSlot } from './iozeganak/ioze.js'

import a from './iozeganak/fontChars/01a.png'
import e from './iozeganak/fontChars/02e.png'
import pa from './iozeganak/fontChars/03pa.png'
import ba from './iozeganak/fontChars/04ba.png'

import da from './iozeganak/fontChars/05da.png'
import ze from './iozeganak/fontChars/06ze.png'
import ka from './iozeganak/fontChars/07ka.png'
import k from './iozeganak/fontChars/08k.png'
import ga from './iozeganak/fontChars/09ga.png'
import ta from './iozeganak/fontChars/10ta.png'
import the from './iozeganak/fontChars/11the.png'
import te from './iozeganak/fontChars/12te.png'
import la from './iozeganak/fontChars/13la.png'
import ma from './iozeganak/fontChars/14ma.png'
import na from './iozeganak/fontChars/15na.png'
import sha from './iozeganak/fontChars/16sha.png'
import io from  './iozeganak/fontChars/17io.png'


import anime from 'animejs'

import solutionsoutpost from './images/SolutionsOutpost.png'

import { JemBalance, getJemBalance, LoreBalance, getLoreBalance, getUserInfo } from './jem.js'

import {Inventory, ISlot } from './inventory.js'

import lore0 from './LoreNFT/0.json'

import loreimg from './images/lore.png'

import { LoreFocus } from './lorefocus.js'

import Player from './player.js'

import pub from './images/paradox-pub.png'

import { Pub } from './pub.js'

import { formatTokenBalance, searchAddress, fromTokenNameTo, fromTokenNameToDecimals, fromTokenNameToAddress } from './tokenUtilities.js'

import { InputView } from './inputview.js'
const poolIDs = Object.keys(poolConfigs)

const dec18 = BigNumber.from(10).pow(18)


function App() {

	// Connecting to Metamask
	const [connected, setConnected] = React.useState(false)
	const [accounts, setAccounts] = React.useState([]);
	const [mmBtnText, setMMBtnText] = React.useState("Connect");

	function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
    }
  	function handleChainChange(chainId) {
       window.location.reload();
    }

	const connectMM = () => {
	    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
	      window.ethereum
	        .request({ method: 'eth_requestAccounts' })
	        .then((newAccounts) => {
	          setAccounts(newAccounts)
	          setConnected(true)})
	    }
	}

	React.useEffect(() => {
		if (connected) {
		  window.ethereum.on('accountsChanged', handleNewAccounts);
		  return () => {
		    window.ethereum.on('accountsChanged', handleNewAccounts);
		  };
		}
	}, [connected]);

	React.useEffect(() => {
		if (connected) {
		  console.log(window.ethereum.chainId)
		  window.ethereum.on('chainChanged', handleChainChange);
		  return () => {
		    window.ethereum.on('chainChanged', handleChainChange);
		  }
		}
	}, [connected])
	
	

		
	React.useEffect( () => {
		if (connected) {
	      if (accounts.length > 0) {
	        setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
	        letItRip();
	       	
	      }
	    }
	}, [connected])

	// -- end of connecting to metamask

	const theInputRef = React.createRef();

	const [theTargetADDY, setTheTargetADDY] = React.useState("");
	const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

	const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);
	const toggleInput = () => {
		if (theInputTOGGLE) {
			setTheInputTOGGLE(false)
		} else {
			setTheInputTOGGLE(true)
		}
	}


	const [theInputINTENT, setTheInputINTENT] = React.useState(false);
	const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
	const smartSetBalanceOfTarget = (balance) => {
		console.log(balance)
		setBalanceOfTarget(balance)
	}


	var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')

	


	const letItRip = () => {
	
		getNSetJemBal()
		getNSetLoreBal()
	
	
		
		getNSetUserInfo()
	}

  	const [isPending, setIsPending] = React.useState(false);


  	const secretEvent = (X, Y, name, condition = true) => {
  		return {
  			"X": X,
  			"Y": Y,
  			"name": name,
  			"condition": condition 
  		}
  	}

  	const whereDidIClick = (event) => {

  		let x = event.pageX + event.target.parentElement.parentElement.scrollLeft
  		let y = event.pageY + event.target.parentElement.parentElement.scrollTop
  		console.log("whereDidIClick",x, y)
  		return [x, y]
  	}

  	const isItASecretLocation = (wic) => {
  		let yeh = false
  		let DEH = null
  		S.forEach(se=>{
  			if (se.condition) {
  				yeh = (wic[0] >= se.X[0] && wic[0] <= se.X[1] && wic[1] >= se.Y[0] && wic[1] <= se.Y[1] ) 
  				if (yeh) DEH=se
  			}
  			
  		})
  		console.log("isItASecretLocation",DEH)
  		return DEH
  	}
const [foundOutpost, setFoundOutpost] = React.useState(false)
  const [enteredOutpost, setEnteredOutpost] = React.useState(false)
  const [jembal, setJembal] = React.useState(0)
  const [userInfo, setUserInfo] = React.useState(0)
  	const [foundPub, setFoundPub] = React.useState(false)
  	const [pubFarmOn, setPubFarmOn] = React.useState(false)
  //const se1 = secretEvent([681, 740], [680, 734], "one")
  const se2 = secretEvent([1717, 1763], [2109, 2142], "move")
  const se3 = secretEvent([10486, 10748], [1944, 2328], "two", foundOutpost)

  const se4 = secretEvent([11331, 11348], [3164, 3174], "lore-intelligence", enteredOutpost)
  const se5 = secretEvent([11388, 11413], [3164, 3174], "lore-creativity", enteredOutpost)
  const se6 = secretEvent([11463, 11485], [3164, 3174], "lore-might", enteredOutpost)
  const se7 = secretEvent([11530, 11536], [3170, 3185], "lore-clarity", enteredOutpost)

  var se8 = secretEvent([10402, 10582], [1577, 1713], "gem-mint", enteredOutpost)

  var seStatue = secretEvent([10900, 11377], [1539, 2040], "equilos", enteredOutpost)

  var seMagicPortal = secretEvent([7747, 8359], [1122, 1692], "magicportal", enteredOutpost)
	var seRune = secretEvent([10761, 11006], [3329, 3581], "rune", enteredOutpost)


	var seTable = secretEvent([11219,11309],[3515,3655], "lore-volumes-prelude", enteredOutpost)	


	var seParadoxPub = secretEvent([2541,3011],[2683,3211], "enterPub", (userInfo > 4))	
	var sePubFarm = secretEvent([1236,2280],[1027,1540], "pubFarm", (userInfo > 4 && foundPub))	


  var S = [se2, se3, se4, se5, se6, se7, se8, seStatue, seMagicPortal, seRune, seTable, seParadoxPub, sePubFarm]

  const [jekput, setJekput] = React.useState("")
  const jackpotter = (event) => {
  	let sheeeee = isItASecretLocation(whereDidIClick(event))
  	if (typeof sheeeee == 'undefined' || sheeeee == null) return false
  	setJekput(sheeeee)
  	console.log("jackpotter",sheeeee)
  }


  const [n, setN] = React.useState(-1)


  const [secretStarted, setSecretStarted] = React.useState(false)
  const ifClickedSecretSetSecretStarted = (event) => {
  	if (n == -1) {
  		console.log("ifClickedSecretSetSecretStarted", n)
  		if (connected) jackpotter(event)

  	}
  }



  React.useEffect(() => {
  	if (jekput != null && jekput != false) {
  			console.log("jekput effect", jekput)
  			if (jekput.name == "move" && !foundOutpost) {

  				setFoundOutpost(true)
  			} else if (jekput.name == "pubFarm") {
  				console.log(" yo ")
  				 setPubFarmOn(true)

  		} else {
  				setSecretStarted(true)
  				if (jekput.name == "gem-mint") {
  					setN(userInfo)
  				} else {
  				
  					if (jekput.name == "enterPub" && !foundPub) {
  						setFoundPub(true)
  					} else {
  						
  							setN(0)
  						
  					}
  				}
  				
  				
  			}
  			
  		}
  	}, [jekput])

  React.useEffect(()=> {
  	if( foundOutpost) {
  		document.getElementById("secret-solutionsOutpostSector").scroll(9700, 1800)
  	}
  }, [foundOutpost])

  const safu = () => {
  	return (typeof ekalaze[jekput.name] != 'undefined') ? ekalaze[jekput.name][n] : 0
  }

  const back = () => {
  	var newN = n
  	if (n > 0) {
  		setN(newN - 1)
  	} else {
  		setSecretStarted(false)
  		setN(-1)
  	}
  }

  const [waiting, setWaiting] = React.useState(false)

  const next = () => {
 
  	if (!waiting) {
  		if (!namingNotiOn) {

  			if (typeof ekalaze[jekput.name][n]["notify"] != 'undefined') {
	  			if (ekalaze[jekput.name][n]["notify"] == "naming") {
		  			setNamingNotiOn(true)
		  		}
	  		} else {
	  	
	  			if (typeof ekalaze[jekput.name][n]["await"] != 'undefined') {
		  			if (ekalaze[jekput.name][n]["await"] == "input") {
			  			setWaiting(true)
			  		}
		  		} else {
		  			if (typeof ekalaze[jekput.name][n]["action"] != 'undefined') {
		  				if (ekalaze[jekput.name][n]["action"] == "enteredOutpost") {
				  			setEnteredOutpost(true)
				  		} 
			  		} 
		  			nextOrEscape() //enteredOutpost
		  		}
	  		}
  			
  		} else {
  			setNamingNotiOn(false)
	  		setWaiting(true)
  		}
  		
  		
  	} else {
  		handleInput(inref.current.value)
  		setWaiting(false)
  		nextOrEscape()
  	}
  	
  }

  const nextOrEscape = () => {
  	 	var newN = n
  	if (typeof ekalaze[jekput.name][n + 1] != 'undefined') {
  		setN(newN + 1)
  	} else {
  		setSecretStarted(false)
  		setN(-1)
  	}
  }

	const inref = React.createRef()

	const handleInput = (inp) => {
		console.log(inp)
	}

	const [namingNotiOn, setNamingNotiOn] = React.useState(false)
	const toggleNamingNoti = () => ((namingNotiOn) ? setNamingNotiOn(false) : setNamingNotiOn(true))


	

	const getNSetJemBal = () => {
		getJemBalance(
			(bal) => {
				setJembal(bal)
			}
			)
	}

	const loreCount = 5
	const loreStart = new Array(loreCount).fill(0)
	const loresStart = new Array(loreCount).fill({})
	const loreToggles = new Array(loreCount).fill(false)
	const [lorebal, setLorebal] = React.useState(loreStart)
	const [lores, setLores] = React.useState(loresStart)
	const [loreToggle, setLoreToggle] = React.useState(loreToggles)

	const getNSetLoreBal = () => {
		var x = new web3.BatchRequest()
		var y = []
		for (var i = 0; i < loreCount; i++) {
			let j = i

			console.log("looped fetch",i)
			
			//console.log(i,getLoreWrapper(i))
			x.add(getLoreWrapper(j))
			y[j] = getNftRequeststring(j)
		}

		x.execute()

		fetch(y[0]).then(response=>response.json()).then(data=>{return data})
			.then(
				(data) => {
					var lorenfts =lores
						lorenfts[0] = data[0]
			
			
					setLores(lorenfts)
					
					//next
					fetch(y[1]).then(response=>response.json()).then(data=>{return data})
			.then(
				(data) => {
					var lorenfts =lores
						lorenfts[1] = data[0]
		
			
					setLores(lorenfts) 
					fetch(y[2]).then(response=>response.json()).then(data=>{return data})
			.then(
				(data) => {
					var lorenfts =lores
						lorenfts[2] = data[0]
		
					setLores(lorenfts)
					fetch(y[3]).then(response=>response.json()).then(data=>{return data})
			.then(
				(data) => {
					var lorenfts = lores
						lorenfts[3] = data[0]
		
					setLores(lorenfts)
					fetch(y[4]).then(response=>response.json()).then(data=>{return data})
			.then(
				(data) => {
					var lorenfts = lores
						lorenfts[4] = data[0]
		
					setLores(lorenfts) })
				})
				})
				})

					})

		
	}



	const getNftCallback = (j, cb =() => {}) => {

		return (data) => {
				var lorenfts =lores
					lorenfts[j] = data[0]
				//console.log(cb)		
				console.log("getNft callback",data[0],j)
				setLores(lorenfts)
				cb()
				}				
			}

	const getLoreWrapper = (j, cb = ()=>{}) => {
		
			return getLoreBalance(j,
				(error, bal) => {
					//console.log(bal)
					var lorebalances = lorebal
					lorebalances[j] = bal
					setLorebal(lorebalances)
					cb()
					
				}
				)
			
		
		
	}

	const getNSetUserInfo = () => {
		getUserInfo(
			(info) => {
				//console.log("info",info)
				setUserInfo(info)

			}
			)
	}


	React.useEffect(() => {
		if (connected) {
			if (Number(userInfo) > 0) {
				setFoundOutpost(true)
				setEnteredOutpost(true)
			}
		}
		
	}, [userInfo])
 
 	const setJemBalAndUserInfo = () => {
 		getNSetJemBal()
 		getNSetUserInfo()
 		nextOrEscape()
 	}

 	const update = () => {
 		getNSetJemBal()
 		getNSetUserInfo()
 		getNSetLoreBal()
 		
 		nextOrEscape()
 	}
	

	const getNft = (id, cb) => {
		console.log("running getNft",id)
		const req = new Request(
			//"http://farmgod.finance/nfts/lore/" + id + ".json", 
			process.env.PUBLIC_URL + '/LoreNFT/' + id + '.json',
			{
				method: "GET",
				cache: "default",
			})

		return () => {return fetch(req).then(response => response.json()).then(data => {
			return data
		}).then(cb) }
	
	}

	const getNftRequeststring = id=>new Request(
			//"http://farmgod.finance/nfts/lore/" + id + ".json", 
			process.env.PUBLIC_URL + '/LoreNFT/' + id + '.json',
			{
				method: "GET",
				cache: "default",
			})




	

	const isLoreOn = (index) => {
		return loreToggle[index]
	}

	const toggleLore = (index) => {
		return () => {
			if (lorebal[index] > 0) {
				if ( loreToggle[index]) {
				
				console.log("loretoggle")
				let shallow = [...loreToggle]
				shallow[index] = false
				setLoreToggle(shallow)
			} else {
			
				console.log("loretoggle")
				let shallow = [...loreToggle]
				shallow[index] = true
				setLoreToggle(shallow)
			}
			}
			
		}
	}


  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");






	var pu = new Pu(web3, theInputRef)
	const [emissionRate, setTheEmissionRate] = React.useState(0)
       
  const getEmissionRate = () => {
    pu.getRate(
      (res) => {
        setTheEmissionRate(res)
      }
      )
  }

const [totalAllocPoints, setTotalAllocPoints] = React.useState(0)
       
  const getTotalAlloc = () => {
    pu.getTotalAlloc(
      (res) => {
        setTotalAllocPoints(res)
      }
      )
  }
    const getStateString = (poolID, isSet = true, isBalance = true ) => {
    const prefix = (!isSet) ? "p" : "setP"
    const meat = (isBalance) ? "oolBalance" : "endingRewards"
    const suffix = "_" + poolConfigs[poolID.toString()]["stake-name"]
    return prefix + meat + suffix  
  }
  const openInputViewAndDoStuff = (addy, intent, argOneIsAddy = false) => {
  
    let theAddy = (argOneIsAddy) ? addy : fromTokenNameToAddress(poolConfigs[addy.toString()]["stake-name"])
    let theName = (argOneIsAddy) ? addy : poolConfigs[addy.toString()]["stake-name"]
    let thePool = (argOneIsAddy) ? addy : addy.toString()
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      
      setTheTargetPOOL(thePool)
      console.log(theTargetPOOL)
      pu.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      pu.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      pu.harvest(
        id,
        () => {
          setIsPending(false)
          triggerGetPendingRewards(id);
        })
    }     
  }
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance)
    setAllowanceOfTarget(allowance)
  }
const triggerGetPendingRewards = (id) => {
    pu.getPendingRewards(
      id,
      (res) => {
        console.log(res + " << should be rewards for pool >> "+id)
        eval(getStateString(id, true, false) + "(res)") 
      }
    )
  }

  const [poolBalance_munnypenni, setPoolBalance_munnypenni] = React.useState(0)
  const [pendingRewards_munnypenni, setPendingRewards_munnypenni] = React.useState(0)

  const [poolBalance_quanta, setPoolBalance_quanta] = React.useState(0)
  const [pendingRewards_quanta, setPendingRewards_quanta] = React.useState(0)

  const [poolBalance_penni, setPoolBalance_penni] = React.useState(0)
  const [pendingRewards_penni, setPendingRewards_penni] = React.useState(0)

  const [poolBalance_eye, setPoolBalance_eye] = React.useState(0)
  const [pendingRewards_eye, setPendingRewards_eye] = React.useState(0)
  const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), eval(getStateString(theTargetPOOL, false)), false)
    } else {
      if (theTargetNAME == "echo") {
        if (balanceOfTarget / 10 ** 9 > 69420) {
          theInputRef.current.value = 69420
        } else {
          theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget, false)
        }
      } else {
        theInputRef.current.value = formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget, false)
      }
      
    }
    
  }

   const onClick_Approve = () => {
    setIsPending(true)
    pu.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }

   // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    pu.depositAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    pu.withdrawAmount(
      theTargetPOOL,
      () => {
        setIsPending(false)
        toggleInput();
        triggerGetPoolBalance(theTargetPOOL);
        triggerGetPendingRewards(theTargetPOOL);
      }
    )
  }

const triggerGetPoolBalance = (id) => {
    pu.getPoolBalance(
      id,
      (res) => {
        console.log(res)
        console.log("^^ pool balance")
        eval(getStateString(id, true, true) + "(res[0])")
      }
    )
  }

const [gotPubInfo, setGotPubInfo] = React.useState(false)
React.useEffect(() => {
	if (pubFarmOn && !gotPubInfo) {
		setGotPubInfo(true)
		getAllRewards()
	    getAllPoolBalances()
	    getEmissionRate()
	    getTotalAlloc()
	    getTotalSupply()
	    getRewardBalance()
	}
},[pubFarmOn])

       



  const [totalSupply, setTotalSupply] = React.useState(0)

  const getTotalSupply = () => {
    pu.totalSupply((res) => {
      setTotalSupply(res)
    })
  }
  

  const [rewardBalance, setRewardBalance] = React.useState(0)

  const getRewardBalance = () => {
    pu.rewardBalance((res) => {
      setRewardBalance(res)
    })
  }

   const getAllRewards = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPendingRewards(index)
    })
  }


  const getAllPoolBalances = () => { 
    let ids = Object.keys(poolConfigs)
    ids.forEach((x, index) => {
      triggerGetPoolBalance(index)
    })
  }


  const setPubOff = () => { 
  	if (pubFarmOn && gotPubInfo) {
  		setPubFarmOn(false)
  		setGotPubInfo(false)
  	} 

  }


	return (
		<div className={"App App--" + connected}>
		 <div className="eye"><img src={logo}/></div>
		  <header className="App-header">
		    <a href="https://ftmscan.com/token/0x496e1693a7b162c4f0cd6a1792679cc48ecbcc8d" target="_blank"><img src={logo} className="App-logo" alt="logo" /></a> 
		    <div className="header-title">
    			<IozeWord><IozeChar tl="a" /><IozeChar tl="pa" /></IozeWord>
    		    <IozeWord><IozeChar tl="pa" /><IozeChar tl="ga"/><IozeChar tl="na" /></IozeWord>
		    </div> 
		  </header>

		  <button className="metamask-btn" onClick={connectMM}>{mmBtnText}</button>




		  <div className={"pending pending--" + isPending}>PENDING</div>
		  <Secret sid="mapOfTruthMultiverseSector" connected={connected}>
		  	<div onClick={ifClickedSecretSetSecretStarted} className={"map"}><img src={truthmapmulti} /></div>
		  </Secret>

		  <div className={"travel-" + foundOutpost}>TRAVELLING</div>

		  <Secret sid="solutionsOutpostSector" found={foundOutpost} connected={connected}>
		  	<div onClick={ifClickedSecretSetSecretStarted} className={"map"}><img src={solutionsoutpost} /></div>
		  </Secret>

		  <Secret sid="paradoxPubSector" found={foundPub} connected={connected}>
		  	<div onClick={ifClickedSecretSetSecretStarted} className={"map"}><img src={pub} /></div>
		  </Secret>

		  <RpgStyleConvo sjbui={setJemBalAndUserInfo} update={update}
		  	theSecret={jekput} theStatement={n} ini={secretStarted} back={back} next={next} waiting={waiting}>
		  </RpgStyleConvo>


		  <Pub name="The Paradox Pub" stat="Intelligence" on={pubFarmOn}>
		  	<button className="farm-btn" onClick={setPubOff}>Close Pub</button>
		  	{Object.keys(poolConfigs).map(id => (
	          <Pool
	     
	            chainId={window.ethereum.chainId}
	            emissionRate={emissionRate}
	            totalAllocPoints={totalAllocPoints} 
	            poolID={id}
	            balance={eval(getStateString(id, false, true))}
	            rewards={eval(getStateString(id, false, false))}
	            minusFunction = {openInputViewAndDoStuff(id, "remove")}
	            plusFunction={openInputViewAndDoStuff(id, "add")} 
	            harvestFunction={returnHarvestFunction(id)} />

	          ))}
		  </Pub>

		  <JemBalance bal={jembal} />
		  
		  <Inventory>
		  	<h3>Lore Collection</h3>
		  	{lorebal.map((bal,index)=><ISlot toggle={toggleLore(index)} key={"lore"+index}><img src={lores[index].image} className={"lore-" + index}/><span>{bal}</span></ISlot>)}
		  	
		  </Inventory>

		  {lores.map((lore,index)=>{
		  	console.log("rendering lore focus map",lore,index)
		  	return(<LoreFocus lore={lore} on={loreToggle[index]} key={loreToggle.join("")+index}  />)})}

		  <InputView 
	        toggle={theInputTOGGLE}
	        intent={theInputINTENT}
	        theInputRef={theInputRef}
	        setInputRefToMAX={setInputRefToMAX}
	        poolBalance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), eval(getStateString(theTargetPOOL, false)))}
	        balance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}
	        allowance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}
	        toggleInput={toggleInput}
	        onClick_Approve={onClick_Approve}
	        triggerDeposit={triggerDeposit}
	        triggerWithdraw={triggerWithdraw} />
		
		  <Player url={process.env.PUBLIC_URL + '/music/Welcome.mp3'} />
		</div>
	);
}

export default App;



export const contractConfigs = {
	"generic": {
		"abi": require("./abis/genABI-dai.json"),
		"address": "0x12245af2d6d298D98019C15FB1001D8712D27A7D"
	},
	"chef": {
		"abi": require("./abis/ParadoxPub.json"),
		"address": "0x2F07769A9E03091e78aA4DDF77afF1e5732b664f"
	}
}

export const poolConfigs = {
	
	"0": {
		"stake-name": "quanta",
		"fee": "0",
		"name": "QUANTA",
		"rate": "100",
		"dex": "none",
		"action": "none",
		"status": "open",
		"pubpooltype": "explorer"
	}, 
	
	"1": {
		"stake-name": "munnypenni",
		"fee": "0",
		"name": "PENNI-MUNNY LP",
		"rate": "4000",
		"dex":"spooky",
		"action": "add",
		"pairA": "0x195fe0c899434fb47cd6c1a09ba9da56a1cca12c",
		"pairB": "0x47d0d625638b56084e76b8720475d175d171af9A",
		"status": "open",
		"pubpooltype": "provider"
		}, 
	"2": {
		"stake-name": "penni",
		"fee": "0",
		"name": "PENNI",
		"rate": "10",
		"dex": "spooky",
		"action": "buy",
		"status": "open",
		"pubpooltype": "patron"
	}, 
	"3": {
		"stake-name": "eye",
		"fee": "0",
		"name": "EYE",
		"rate": "1",
		"dex": "spooky",
		"action": "buy",
		"status": "open",
		"pubpooltype": "townie"
	}

}

export const rewardToken = {
	"name": "penni",
	"displayname": "PENNI",
	"symbol": "PENNI",
	"address": "0x47d0d625638b56084e76b8720475d175d171af9A",
	"icon": require("./images/penni.png")
}

export const tokenConfigs = {
	"penni": {
		"name": "PENNI",
		"address": "0x47d0d625638b56084e76b8720475d175d171af9A",
		"icon": require("./images/penni.png"),
		"decimals": 18
	},
	"quanta": {
		"name": "QUANTA",
		"address": "0x010226C777E27e04AE31d4CDDE9510863f5f1Aaa",
		"icon": require("./images/quanta.png"),
		"decimals": 18
	},
	"eye": {
		"name": "EYE",
		"address": "0x496e1693a7b162c4f0cd6a1792679cc48ecbcc8d",
		"icon": require("./images/eye.png"),
		"decimals": 18
	},
	"munnypenni": {
		"name": "MUNNY-PENNI LP",
		"address": "0x67ca2b4186b0221eb6b082afe16db0c6b8dbe440",
		"icon": require("./images/munnypenni.png"),
		"decimals": 18
	}
}
import React from 'react'

export const Pub = (props) => {
	const [collapsed, setCollapsed] = React.useState(true)
	const toggle = () => ( (collapsed) ? setCollapsed(false) : setCollapsed(true) )
	return (
		<div id={"pub-" + props.name} className={"pub pub--" + props.on }>
			<h1>{props.name}</h1><br/>
			<h2>MS2 {props.stat} Pub</h2><br/>
			<h3 onClick={toggle}>Information</h3>
			<div className={"info-collapse info-collapse--" + collapsed}>

				<p>This is a Multiverse Pub. Pubs are hidden farms. Each pub focuses on a specific stat: in the future, gear will be available at each pub, purchased with that pub's REWARD token. The gear will increase the stat associated with the pub!</p>
				<p>Each Pub has four pools. MUNNY-REWARD, QUANTA, REWARD, LOCAL CORE TOKEN. The allocation points are (4000, 100, 10, 1) respectively. The LOCAL CORE TOKEN is the Core token for the universe that the pub is near.</p>
				<p>Each Pub has an owner, simply a character, and the REWARD for that pub is the owner's name.</p>
				<p>Welcome to The Paradox Pub, and Intelligence Pub, owned by Paradox Penni. Here, you can earn PENNI by staking MUNNY-PENNI LP, QUANTA, PENNI, and - since we are near the Truth Universe - EYE.</p>
				<p>Eventually, you will be able to use PENNI here, and here alone, to buy items that boost your character's Intelligence.</p>
			</div>
			{props.children}
		</div>
	)
}
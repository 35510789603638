import React from 'react'

import { formatTokenBalance, fromTokenNameToDecimals, fromTokenNameToAddress, fromTokenNameToIcon } from './tokenUtilities.js'
import { poolConfigs, rewardToken, tokenConfigs } from './pubConfig.js';
import { scannerBaseURLByChain, getActionLink, getDexNativeSymbol } from './ChainTools.js'

//formatTokenBalance(fromTokenNameToDecimals(poolConfigs["0"]["stake-name"]), poolBalanceYOMOPU)
const p = (x, y) => x + " " + y
const p0 = p(0,0)
const pointArrayRectangle = (xMax, yMax) => {
      return [
         p(0,0), 
         p(xMax,0), 
         p(xMax,yMax), 
         p(0,yMax)
      ]
    }

    const morph = {p:0, newP: "p(0,0)"}
export function Pool (props) {
    const thePool = poolConfigs[props.poolID]
    const thePoolTokenName = poolConfigs[props.poolID]["stake-name"]
    const thePoolFee = poolConfigs[props.poolID]["fee"]
    const thePoolRate = poolConfigs[props.poolID]["rate"]
    const thePoolAddy = fromTokenNameToAddress(thePoolTokenName)
    const xMax = 640;
    const yMax = xMax;
    var ps = pointArrayRectangle(xMax, yMax)
    var points = ps.join(" ")
    var psB = ps
    psB[morph["p"]] = eval(morph["newP"]) 
    var pointsB =  psB.join(" ")
    let tokenA = getDexNativeSymbol(thePool["dex"])
    let tokenB = thePoolAddy
    if (thePool["action"] === "add") {
      tokenA = thePool["pairA"]
      tokenB = thePool["pairB"]
    }


    return ( 
      <div className={"pool poolsvg--" + ((props.svgContainer) ? "true" : "false") + " pool--" + thePool["status"] + " poolrate--" + thePool["rate"]}>
 
 
        <div className="pool__textName"><a href={getActionLink(props.chainId, thePool["dex"], thePool["action"],tokenA,tokenB)} target="_blank">
              <img className={thePoolTokenName}  src={fromTokenNameToIcon(thePoolTokenName)["default"]} /></a><span>{thePool["name"]}</span></div><br/>
        <div className="pool__balance">
          Balance: <strong>{
            formatTokenBalance(
              fromTokenNameToDecimals(thePoolTokenName), 
              props.balance)}</strong>
        </div>
        <div className="pool__rewards">Pending: <strong>{formatTokenBalance(18, props.rewards)}</strong></div>
        <div className="pool__depositFee">Deposit Fee: <strong>{thePoolFee}%</strong></div>
        <div className="pool__controls">
          <a href={scannerBaseURLByChain(props.chainId) + "/token/" + fromTokenNameToAddress(thePoolTokenName)} target="_blank">CONTRACT</a>
          <button className="pool__btn" onClick={props.minusFunction}>
            -
          </button>
          <button className="pool__btn" onClick={props.plusFunction}>
            +
          </button>
          <button className="pool__btn" onClick={props.harvestFunction}>
            Harvest
          </button>
        </div>
        <div className="pool__rate">{formatTokenBalance(0, (props.emissionRate / 10 ** 18) *( thePoolRate/props.totalAllocPoints))} {rewardToken["name"]}/second</div>

        <div className="pub-pool-type">{thePool["pubpooltype"]}</div>
      </div>
    )
    
  } 
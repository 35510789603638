import React from 'react'

export const Secret = (props) => {
	const [secretFound, setSecretFound] = React.useState(false)
	const X = () => ((props.connected) ? ((secretFound) ? setSecretFound(false) : setSecretFound(true)) : ()=>{})
	if (props.sid !== "mapOfTruthMultiverseSector" && !secretFound) {
		if (props.found == true) X()
	}
	return (
		<div id={"secret-" + props.sid} className={"secret secret--" + props.sid + " secret-found--" + secretFound}>
			{(props.sid == "mapOfTruthMultiverseSector") ? <button className="shh" onClick={X}>.</button> : ""}
			{props.children}
		</div>
	)
}
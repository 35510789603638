import za from './fontChars/01a.png'
import zze from './fontChars/02e.png'
import zpa from './fontChars/03pa.png'
import zba from './fontChars/04ba.png'

import zda from './fontChars/05da.png'
import zzze from './fontChars/06ze.png'
import zka from './fontChars/07ka.png'
import zk from './fontChars/08k.png'
import zga from './fontChars/09ga.png'
import zta from './fontChars/10ta.png'
import zthe from './fontChars/11the.png'
import zte from './fontChars/12te.png'
import zla from './fontChars/13la.png'
import zma from './fontChars/14ma.png'
import zna from './fontChars/15na.png'
import zsha from './fontChars/16sha.png'
import zio from  './fontChars/17io.png'
import React from 'react'

const a = za 
const e = zze 
const pa = zpa 
const ba = zba 
const da = zda 
const ze = zzze 
const ka = zka 
const k = zk 
const ga = zga 
const ta = zta 
const the= zthe
const te = zte 
const la = zla 
const ma = zma 
const na = zna 
const sha = zsha
const io = zio 


const alpha = ["a", 
"e",
"pa", 
"ba", 
"da", 
"ze",
"ka", 
"k", 
"ga", 
"ta", 
"the",
"te", 
"la", 
"ma", 
"na", 
"sha",
"io"] 


export const IozeWord = (props) => (
	<div className="ioze__word">
		{props.children}
	</div>
)


export const IozeChar = (props) => (
	<div className={"ioze__char char--" + props.tl}>
		<img src={eval(props.tl)} />
	</div>
)
export const IozeOpt = (props) => {
	
	return (
	<div onClick={props.toggleOn} className={"opt opt--" + props.on}>
		<div className={"ioze__char char--" + props.tl}>
			<img src={eval(props.tl)} />
		</div>
	</div>
)}


export const tlToIoze = (tl) => {
	let ioze
	try {
		ioze = eval(tl)
	} catch (error) {
		ioze = tl
	}
	return ioze
}


export const IozeSelect = () => {
	const [on,setOn] = React.useState("a")
	const toggleOn = (alph,i) => (setOn(alph))

	return (
		<div>
		<div className="ioze-select">  
			{alpha.map((alph,index)=><IozeOpt tl={alph} toggleOn={()=>{toggleOn(alph,index)}} />)}
		</div>
		<div className={"ioze-display"}>
			<IozeChar tl={iozeAlphabet[on]["tl"]} />
			<p><span>Transliteration - </span>{iozeAlphabet[on]["tl"]}</p>
			<p><span>Sound - </span>{iozeAlphabet[on]["sound"]}</p>
			<p><span>Meaning - </span>{iozeAlphabet[on]["meaning"]}</p>
		</div>
		</div>
		)
}



export const IozeSelectX = (props) => {
	


	return (
		<span className="iozeX">
		<div className={"ioze-display"}>
			<IozeChar tl={iozeAlphabet[props.on]["tl"]} />
	
		</div>
		<div className="ioze-select">  
			{alpha.map((alph,index)=>((alph!=="k" || props.last) ? <IozeOpt tl={alph} toggleOn={()=>{props.toggleOn(alph,index)}} /> : ""))}
		</div>
		
		</span>
		)
}

export const iozeDictionary = {
	"0": "apa",
	"1": "bapa",
	"2": "gapa",
	"3": "daka",
	"4": "epak",
	"5": "zek",
	"6": "etak",
	"7": "thek",
	"8": "iotak",
	"9": "kapak",
	"10": "lak",
	"11": "mak"
}


export const iozeAlphabet = {
"a": {
	"tl": "a",
	"sound": "ɑː",
	"meaning": "nothing; nothingness; no; subjective falsehood",
	"def": [1,0,0,0],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},
"e":{
	"tl": "e",
	"sound": "ɛ",
	"meaning": "everything (as in unity); yes; subjective truthfulness",
	"def": [0,1,0,0],
	"stats": [
		[0,0,1,0],
		[0,1,0,0],
		[1,0,0,1]
	]
},
"pa": {
	"tl": "pa",
	"sound": "pɑː",
	"meaning": "of; from; indicates possession when attached to the front of a noun (and when Green at the end of a noun)",
	"def": [0,0,1,0],
	"stats": [
		[0,1,0,0],
		[1,0.5,0,0.5],
		[0,0,1,1]
	]
},
"ba": {
	"tl": "ba",
	"sound": "bɑː",
	"meaning": "something",
	"def": [0.707,0.707,0,0],
	"stats": [
		[0.707,0,0.707,0],
		[0,1.061,0.707,0.354],
		[0.707,1,0,1.414]
	]
},
"da": {
	"tl": "da",
	"sound": "dɑː",
	"meaning": "emotion; irrationality",
	"def": [0.707,0,0.707,0],
	"stats": [
		[0.707,1,0,0],
		[0.707,0.707,0.707,0.707],
		[0,1,0,1.414]
	]
},
"ze":{
	"tl": "ze",
	"sound": "zɛ",
	"meaning": "experience; touch, in a vague sense",
	"def": [0.707,0,0,0.707],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},
"ka": {
	"tl": "ka",
	"sound": "kɑː",
	"meaning": "invariant / invariance in a mathematical sense",
	"def": [0,0.707,0.707,0],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},

"ga": {
	"tl": "ga",
	"sound": "gɑː",
	"meaning": "a happening; a confirmable event",
	"def": [0,0.707,0,0.707],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},
"ta":{
	"tl": "ta",
	"sound": "tɑː",
	"meaning": "time; the arrow of increasing entropy within a universe; the direction of causality in the multiverse",
	"def": [0,0,0.707,0.707],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
}, 
"the":{
	"tl": "the",
	"sound": "θɛ",
	"meaning": "randomness; the fabric of reality",
	"def": [0,0,-1,1],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},
"te":{
	"tl": "te",
	"sound": "tɛ",
	"meaning": "self-similarity; the fabric of conscious thought",
	"def": [0,-1,0,1],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
},	
"la":{
	"tl": "la",
	"sound": "lɑː",
	"meaning": "repetition; a cycle; the fabric of knowledge",
	"def": [0.577,0.577,0,0.577],
	"stats": [
		[1,0,0,0],
		[0,0.5,1,0.5],
		[0,1,0,1]
	]
}, 
"ma":{
	"tl": "ma",
	"sound": "mɑː",
	"meaning": "it; especially this thing",
	"def": [0.577,0,0.577,0.577],
	"stats": [
		[1.155,1.155,0.577,0.577],
		[0.577,0.577,0.577,1.155],
		[0,0.577,0.577,1.732]
	]
}, 
"na":{
	"tl": "na",
	"sound": "nɑː",
	"meaning": "what",
	"def": [0.5,0.5,0.5,0.5],
	"stats": [
		[1,1,1,0.5],
		[0.5,1,0.5,1],
		[0.5,0.5,0.5,2]	
	]
}, 
"sha": {
	"tl": "sha",
	"sound": "shɑː",
	"meaning": "I / Me",
	"def": [0,0.577,0.577,0.577],
	"stats": [
		[0.577,1.155,0.577,0.577],
		[0.577,0.866,0,0.866],
		[0.577,0,0.577,1.732]
	]
},
"io": {
	"tl": "io",
	"sound": "ioʊ",
	"meaning": "a group; a collection; a set of things",
	"def": [0,0,0,1],
	"stats": [
		[1,1,1,1],
		[0,0,0,1],
		[0,0,0,1]
	]
},
"k": {
	"tl": "k",
	"sound": "k",
	"meaning": "usually added to a word to limit its scope (Iozegana loosely translates to Our Truth, while Iozeganak loosely translates to Our one Truth)",
	"def": [0.6,0.6,0.6,0.6],
	"stats": [
		[0,0,0,0],
		[0,0,0,0],
		[0.6,0.6,0.6,2.2]
	]
}
}



export const getStatEffectFromSlot = (alpha,slot) => {
	let vect = iozeAlphabet[alpha]["def"]
	//console.log(alpha, slot)

	const operator = operators[slot]
	//console.log(operator)
	let sumprod = [0,0,0,0]
	for (let i = 0; i < 4; i++) {
		

		for (let j = 0; j< 4; j++) {
			sumprod[i] = sumprod[i] + operator[i][j]*vect[j]
		}
	}

	return sumprod
}




const operators = {
	"0": [
		[1,0,0,1],
		[0,0,1,1],
		[0,1,0,1],
		[0,0,0,1]
	],
	"1": [
		[0,0,1,0],
		[0.5,1,0.5,0],
		[1,0,0,0],
		[0.5,0,0.5,1]
	],
	"2": [
		[0,1,0,0],
		[1,0,0,0],
		[0,0,1,0],
		[1,1,1,1]
	]
}
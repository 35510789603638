import React from 'react'

import { ekalaze } from './u2prelude00001.js'

import chars from './images/ms2.png'

export const RpgStyleConvo = (props) => {
	//console.log("RpgStyleConvo",props.theSecret)
	var theSecret = (typeof props.theSecret == 'undefined') ? false : props.theSecret
	// console.log("RpgStyleConvo",theSecret)
	var theStatement = (props.theStatement > -1) ? props.theStatement : false
	let cb = props.next
	if (theSecret) {
		console.log(theSecret)
		theSecret = (typeof theSecret.name == 'undefined') ? false : props.theSecret.name
		if (theSecret == "gem-mint") {
				console.log("gemmint secret")
				cb = props.sjbui
			} else {
				if (theSecret.indexOf("lore") > -1) {
					cb = props.update
				}
			}

		if (theSecret) {

			theSecret = (typeof ekalaze[theSecret] == 'undefined') ? false : ekalaze[theSecret]
			

			if (theSecret && (theStatement || theStatement > -1) ) {
				theStatement = (typeof theSecret[theStatement] != 'undefined') ? theSecret[theStatement] : false
				
				
			}
		}
		
	}
	
	

	return (
	<div className={"convo convo-init--" + props.ini}>
		<div className={"statement speaker-name--" + ((theStatement) ? theStatement.speaker : "u")}>
			{(theStatement) ? theStatement.text : "..."}
			{(typeof theStatement.choices !== 'undefined') ? 
				theStatement.choices.map(
					(choice,index)=><button key={choice+index}
						className="convo-choice" 
						onClick={
							()=>{
								((index == 0) ?
								theStatement.answerAction[index](
									cb
									) : theStatement.answerAction[index](
										props.next
									))

							}}>{choice}</button>) : "" }
		</div>
		<div className={"speaker"}>
			<h3>{(theStatement) ? theStatement.speaker : "..."}</h3>
			<div className="speaker-image">{<img className={"speaker-" + theStatement.speaker} src={chars} />}</div>
		</div>
		<div className="controls">
			{(typeof theStatement.choices == 'undefined') ? <button className="convo-nav convo-nav--back" onClick={props.back}>back</button> : "" }
			{(typeof theStatement.choices == 'undefined') ? <button className="convo-nav convo-nav--next" onClick={props.next}>next</button> : "" }

			
		</div>
		
	</div>
)
}
import { mintJem, mintLore } from './jem.js'
export const ekalaze = {
	"one": {
		"0": {
			"speaker": "",
			"text": "You step off the TBTC and a hooded figure approaches."
		},
		"1": {
			"speaker": "Embel",
			"text": "Ah, another lost traveler? What is your name child...?",
			"notify": "naming",
			"await": "input"
		},
		"2": {
			"speaker": "Embel",
			"text": "Another of Maxus'? Very well then, I'll give you a tour",
			"await": "input"
		}
	},
	"move": {
		"location": "SolutionsOutpost"
	},
	"two": {
		"0": {
			"speaker": "",
			"text": "You go to knock on the door but it swings opens..."
		},
		"1": {
			"speaker": "Solution",
			"text": "You know I cannot do that, Embel. Now please leave. See.. another is here already, and I sense more on the way. I will send them up to you when they are ready.",
		},
		"2": {
			"speaker": "Embel",
			"text": "Solution, Solution, Solution.., you must change your mind on this. And you will. I see it each iteration and each iteration you come around... *he shakes his head* just this one you seem to be taking longer."
		},
		"3": {
			"speaker": "Solution",
			"text": "Keep ignoring the signs old man... you know this is Ga'a Gana; you just won't admit it yet. *Embel floats past you. He turns his head briefly and your eyes lock: for a moment, you can sense the cause of his fear...*"
		},		
		"4": {
			"speaker": "Solution",
			"text": "Well.. ? *he looks at you* Are you functioning? Get inside! Life is limited after all."
		},
		"5": {
			"speaker": "",
			"text": "*You walk inside, clueless as to why.*"
		},
		"6": {
			"speaker": "Solution",
			"text": "Shit... you need some explanation now don't you."
		},
		"7": {
			"speaker": "Solution",
			"text": "Shit... you need some explanation now don't you.",
			"choices": ["Who are you?","Wen moon?"],
			"answerAction": [(cb = () => {})=>{cb()},  ()=>{window.location.reload()}]
		},
		"8": {
			"speaker": "Solution",
			"text": "First off, I am Solution... *he stares at you.. waiting* Right.. well then. This the Multiverse. Right now you are in Multiverse Sector 2, or MS2 for short. MS2 surrounds the Truth Brane.. or more commonly known as the Truth Universe."
		},
		"9": {
			"speaker": "Solution",
			"text": "You are somewhat early in the iteration so Truth has yet to Bang... Right... um... a universe that hasn't Banged cannot be interacted with yet. Explore the outskirts of Truth while you wait."
		},
		"10": {
			"speaker": "Solution",
			"text": "Definitely take a look around my place first. Few books on the shelf might be helpful, and I got some JEMS laying around somewhere. Take what you need. And head out. There's a portal on the far side of the bridge if you want to pop through before going up to Embel's Channel."
		},
		"11": {
			"speaker": "Solution",
			"text": "Don't get to drunk though...Penni's got a way about her...",
			"action": "enteredOutpost"
		}

	},
	"lore-intelligence": {
		"0": {
			"speaker": "LORE: Intelligence - By Jahrel - Pg. 1",
			"text": "As all Deanttersitin are aware, the Critical Iteration was host to a series of events that set in motion the current branch collapse we are experiencing. Jars and Azeret fornicated in Toray's Metabrane producing the Initial Mors. It became of the utmost importance then, that I implement a system that allowed other conscious beings to join me here."
		},
		"1": {
			"speaker": "LORE: Intelligence - By Jahrel - Pg. 2",
			"text": "My first instinct was to add a way for Intelligence to be expressed in the Multiverse. Anyone who has met Solution knows how that turned out. However, now with the other expressions - or stats, be it as it may - we find ourselves in quite a similar experience to what Life is like."
		},
		"2": {
			"speaker": "LORE: Intelligence - By Jahrel - Pg. 3",
			"text": "Intelligence, in Ga'a Gana, decreases the likelihood of negative outcomes and increases the likelihood of positive outcomes. Until Ga'a Gana, it won't have any effect.",
			"choices": ["Mint this Lore NFT.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintLore(0, cb)
			},  (cb)=>cb()]
		}
	},
	"lore-creativity": {
		"0": {
			"speaker": "LORE: Creativity - By Jahrel - Pg. 1",
			"text": "Another bit of common knowledge among Deanttersitin: the First Delta saw a large number of improvements. Mors, becoming increasingly aware of our presence, provided enough Ekalaze to allow for a new expression. Creativity."
		},
		"1": {
			"speaker": "LORE: Creativity - By Jahrel - Pg. 2",
			"text": "Gohtel, you may known them as Homer, was the Deanttersiti that heralded this change."
		},
		"2": {
			"speaker": "LORE: Creativity - By Jahrel - Pg. 3",
			"text": "Creativity, in Ga'a Gana, decreases the costs of creative actions and the commissions charged by the Council.",
			"choices": ["Mint this Lore NFT.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintLore(1, cb)
			},  (cb)=>cb()]
		},
	},
	"lore-might": {
		"0": {
			"speaker": "LORE: Might - By Jahrel - Pg. 1",
			"text": "Might was perhaps my most controversial additon to our reality. But it was by popular demand, all manner of beings like to fight."
		},
		"1": {
			"speaker": "LORE: Might - By Jahrel - Pg. 2",
			"text": "Comically enough, it was some relatively unimportant iteration's Brad Pitt that inspired this decision. His role in Fight Club left a tangible five-dimensional effect on his soul."
		},
		"2": {
			"speaker": "LORE: Might - By Jahrel - Pg. 3",
			"text": "Might, in Ga'a Gana, increases the strength of physically expressed attacks.",
			"choices": ["Mint this Lore NFT.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintLore(2, cb)
			},  (cb)=>cb()]
		}
	},
	"lore-clarity": {
		"0": {
			"speaker": "LORE: Clarity - By Jahrel - Pg. 1",
			"text": "Pa'Iomak - Ioze Ba Telaze - Ekalaze"
		},
		"1": {
			"speaker": "LORE: Clarity - By Jahrel - Pg. 2",
			"text": "Clarity increases the Tier of available Mathemagical Spells."
		},
		"2": {
			"speaker": "LORE: Clarity - By Jahrel - Pg. 3",
			"text": "Pa'Iomak Pa'Ga'a Gana, Gana Iomak Azekamaze.",
			"choices": ["Mint this Lore NFT.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintLore(3, cb)
			},  (cb)=>cb()]
		}
	},
	"gem-mint": {
		"0": {
			"speaker": "",
			"text": "You have found a pile of 5 JEMS, mint 1 JEM?",
			"choices": ["Mint it.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintJem(0,cb)
			},  (cb)=>cb()]
		},
		"1": {
			"speaker": "",
			"text": "You have found a pile of 5 JEMS, there are 4 JEMS left, mint 1 JEM?",
			"choices": ["Mint it.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintJem(1, ()=>{ cb()})
			},  (cb)=>cb()]
		},
		"2": {
			"speaker": "",
			"text": "You have found a pile of 5 JEMS, there are 3 JEMS left, mint 1 JEM?",
			"choices": ["Mint it.","Not now."],
			"answerAction": [(cb)=>{
				mintJem(2, cb)
			},  (cb)=>cb()]
		},
		"3": {
			"speaker": "",
			"text": "You have found a pile of 5 JEMS, there are 2 JEMS left, mint 1 JEM?",
			"choices": ["Mint it.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintJem(3, cb)
			},  (cb)=>cb()]
		},
		"4": {
			"speaker": "",
			"text": "You have found a pile of 5 JEMS, mint the last JEM?",
			"choices": ["Mint it.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintJem(4, cb)
			},  (cb)=>cb()]
		}
	},

	"equilos": {
		"0": {
			"speaker": "",
			"text": "*Some statue, wonder who this is.*"
		},
		"1": {
			"speaker": "Solution",
			"text": "That is Equilos, otherwise known as The Conscious Multiverse or The Initial Mors."
		}
	},

	"magicportal": {
		"0": {
			"speaker": "",
			"text": "*Hmmm, looks like some sort of portal.*"
		},
		"1": {
			"speaker": "Solution",
			"text": "That will be a portal to Truth, once the universe bangs."
		}
	},

	"rune": {
		"0": {
			"speaker": "",
			"text": "*I wonder what this could be...*"
		},
		"1": {
			"speaker": "Solution",
			"text": "That is a Mathemagical Equation. You'll understand one day. Please leave it alone for now."
		}
	},

	"lore-volumes-prelude": {
		"0": {
			"speaker": "LORE: Prelude (The Volumes: Book One - Kereth)",
			"text": "*You look at the pages and realize this is a piece of a much larger work.*"
		},
	
		"1": {
			"speaker": "LORE: Prelude (The Volumes: Book One - Kereth)",
			"text": "{{Mint this NFT to read it}}",
			"choices": ["Mint this Lore NFT.","Not now."],
			"answerAction": [(cb = () => {})=>{
				mintLore(4, cb)
			},  (cb)=>cb()]
		}
	},

	"enterPub": {
		"location":"ParadoxPub"
	},

	"pubFarm": {
		
	}

}
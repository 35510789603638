export const contractConfigs = {
	"generic": {
		"abi": require("./abis/genABI-dai.json"),
		"address": "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e"
	},
	"chef": {
		"abi": require("./abis/chef.json"),
		"address": "0xD39936fFCEa70fE21211CAd6378C402d9F567b6B"
	},
	"jem": {
		"abi":require("./abis/jem.json"),
		"address": "0x3477Bb0aEFAC13a936df333CcF82b54AB08A63F9"
	},
	"lore": {
		"abi":require("./abis/loreNFT.json"),
		"address": "0x06032A57A83d86318cDe8772066e6E05f9f6B574"
	},
	"multiverse": {
		"abi":require("./abis/multiversemaster.json"),
		"address": "0xdD824E4E2B51d536DD26aB4dAB4e026456E778dD"
	},
	"munny": {
		"abi": require("./abis/genABI-dai.json"),
		"address": "0x195fe0c899434fb47cd6c1a09ba9da56a1cca12c"
	}
}